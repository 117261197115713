import "./App.css";

function App() {
  return (
    <div className="App">
      <div className="main">
        <img src="/kit-modified.png" className="image" alt="logo" />
        <div style={{ color: "#F8292C", fontSize: "1.3rem" }}>
          Welcome To KitCat Community Takeover
        </div>
        <div
          style={{
            color: "#2EA6E1",
            fontSize: "1.2rem",
            marginTop: "20px",
            wordBreak: "break-all",
          }}
        >
          CA: EEo7ckzCpcGqANLBDsCvUBvD5ywVJDcMDiqMYinQYLHa
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "20px",
          }}
        >
          <div style={{ position: "relative" }}>
            <img
              className="chocolateImage"
              src="/chocolate.png"
              alt="chocolate"
            />
            <div
              style={{
                textAlign: "center",
                fontSize: "1.5rem",
                position: "relative",
                zIndex: 2,
                color: "#B760EB",
              }}
            >
              Join us and experience what a real takeover looks like. See what a
              dedicated community can achieve together. Hop on now and don’t
              miss the chance to be part of Solana’s best memecoin takeover with
              KitCat!
            </div>
            <div
              style={{
                fontSize: "1.6rem",
                display: "flex",
                justifyContent: "center",
                gap: "30px",
                marginTop: "30px",
              }}
            >
              <a
                href="https://dexscreener.com/solana/EEo7ckzCpcGqANLBDsCvUBvD5ywVJDcMDiqMYinQYLHa"
                target="_blank"
                rel="noreferrer"
                style={{ color: "red" }}
              >
                Chart
              </a>
              <a
                href="https://t.me/KitCatcto"
                target="_blank"
                rel="noreferrer"
                style={{ color: "#2EA6E1" }}
              >
                Telegram
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
